import React from 'react';

const MoreIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6665 4.16667C11.6665 3.25 10.9165 2.5 9.99984 2.5C9.08317 2.5 8.33317 3.25 8.33317 4.16667C8.33317 5.08333 9.08317 5.83333 9.99984 5.83333C10.9165 5.83333 11.6665 5.08333 11.6665 4.16667Z"
        stroke="#292D32"
      />
      <path
        d="M11.6665 15.8334C11.6665 14.9167 10.9165 14.1667 9.99984 14.1667C9.08317 14.1667 8.33317 14.9167 8.33317 15.8334C8.33317 16.7501 9.08317 17.5001 9.99984 17.5001C10.9165 17.5001 11.6665 16.7501 11.6665 15.8334Z"
        stroke="#292D32"
      />
      <path
        d="M11.6665 9.99992C11.6665 9.08325 10.9165 8.33325 9.99984 8.33325C9.08317 8.33325 8.33317 9.08325 8.33317 9.99992C8.33317 10.9166 9.08317 11.6666 9.99984 11.6666C10.9165 11.6666 11.6665 10.9166 11.6665 9.99992Z"
        stroke="#292D32"
      />
    </svg>
  );
};

export default MoreIcon;
