import React from 'react';

const LeadsIcon: React.FC = () => {
  return (
    <span
    role="img"
    className="ant-menu-item-icon"
  >
    <svg
      width="22"
      height="22"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6.19995V10C11.21 10.05 10.05 11.21 10 14H6.2C3.2 14 2 12.8 2 9.80005V6.19995C2 3.19995 3.2 2 6.2 2H9.8C12.8 2 14 3.19995 14 6.19995Z"
        stroke="#2E2A2A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.96019 5.87008C6.43019 5.51008 5.7302 5.5101 5.2002 5.8901"
        stroke="#2E2A2A"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9602 5.87008C10.4302 5.51008 9.7302 5.5101 9.2002 5.8901"
        stroke="#2E2A2A"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.15981 11.4202H5.83981C5.53981 11.4202 5.2998 11.1801 5.2998 10.8801C5.2998 9.39013 6.50981 8.18018 7.99981 8.18018C8.63981 8.18018 9.2298 8.40014 9.6898 8.77014"
        stroke="#2E2A2A"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 14.2V17.8C22 20.8 20.8 22 17.8 22H14.2C11.2 22 10 20.8 10 17.8V14C10.05 11.21 11.21 10.05 14 10H17.8C20.8 10 22 11.2 22 14.2Z"
        stroke="#2E2A2A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9602 13.6201C14.4302 13.9801 13.7302 13.9801 13.2002 13.6001"
        stroke="#2E2A2A"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.9602 13.6201C18.4302 13.9801 17.7302 13.9801 17.2002 13.6001"
        stroke="#2E2A2A"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8398 16.1802H18.1598C18.4598 16.1802 18.6998 16.4201 18.6998 16.7201C18.6998 18.2101 17.4898 19.4202 15.9998 19.4202C14.5098 19.4202 13.2998 18.2101 13.2998 16.7201C13.2998 16.4201 13.5398 16.1802 13.8398 16.1802Z"
        stroke="#2E2A2A"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    </span>
  );
};

export default LeadsIcon;
