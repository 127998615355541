import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import AgentSelect from './AgentSelect';
import StatusSelect from './StatusSelect';
import CitySelect from './CitySelect';
import SegmentSelect from './SegmentSelect';
import ProductTypeSelect from './ProductTypeSelect';
import ChannelTypeSelect from './ChannelTypeSelect';
import QuantitySelect from './QuantitySelect';
import BrandSelect from './BrandSelect';
import DateSelect from './DateSelect';

type FiltersType = {
  agent: string[];
  status: string[];
  city: string[];
  segment: string[];
  productType: string[];
  channel: string[];
  quantity: string[];
  brand: string[];
  createdAt: string | null;
};

const FilterModal = ({
  setvalues,
  values,
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: () => void;
  setvalues: React.Dispatch<React.SetStateAction<FiltersType>>;
  values: FiltersType;
}) => {
  const [filters, setFilters] = useState<FiltersType>(values);

    useEffect(() => {
      setFilters(values);
    }, [values]);

  const handleApply = () => {
    setvalues(filters);
    onClose();
  };
  const clearFilters = () => {
    setFilters({
      agent: [],
      status: [],
      city: [],
      segment: [],
      productType: [],
      channel: [],
      quantity: [],
      brand: [],
      createdAt: null,
    });
  };
  const clearvalues = () => {
    setvalues({
      agent: [],
      status: [],
      city: [],
      segment: [],
      productType: [],
      channel: [],
      quantity: [],
      brand: [],
      createdAt: null,
    });
  };

  // Function to handle Clear
  const handleClear = () => {
    clearFilters();
    clearvalues();
  };

  const onCancelHandler = () => {
    onClose();
    clearFilters();
  };

  return (
    <Modal
      title="Filters"
      open={visible}
      onCancel={onCancelHandler}
      footer={null}
      width={650}
      className="custom-modal"
    >
      <div className="grid grid-cols-2 gap-x-6 gap-y-2">
        <AgentSelect
          value={filters.agent}
          onChange={(val) => setFilters((prev) => ({ ...prev, agent: val }))}
        />
        <StatusSelect
          value={filters.status}
          onChange={(val) => setFilters((prev) => ({ ...prev, status: val }))}
        />
        <CitySelect
          value={filters.city}
          onChange={(val) => setFilters((prev) => ({ ...prev, city: val }))}
        />
        <SegmentSelect
          value={filters.segment}
          onChange={(val) => setFilters((prev) => ({ ...prev, segment: val }))}
        />
        <ChannelTypeSelect
          value={filters.channel}
          onChange={(val) => setFilters((prev) => ({ ...prev, channel: val }))}
        />
        <ProductTypeSelect
          value={filters.productType}
          onChange={(val) =>
            setFilters((prev) => ({ ...prev, productType: val }))
          }
        />
        <QuantitySelect
          value={filters.quantity}
          onChange={(val) => setFilters((prev) => ({ ...prev, quantity: val }))}
        />
        <BrandSelect
          value={filters.brand}
          onChange={(val) => setFilters((prev) => ({ ...prev, brand: val }))}
        />

        {/* Created At */}
        <div className="w-full">
          <DateSelect
            value={filters.createdAt}
            onChange={(val) =>
              setFilters((prev) => ({ ...prev, createdAt: val }))
            }
          />
        </div>
      </div>

      {/* Buttons */}
      <div className="flex justify-end gap-4 mt-4 bg-white">
        <Button
          className="bg-white text-[#4A4747] px-5 rounded-lg w-[115px] h-[40px] hover:!text-black hover:!border-[#DBD7D7]"
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button
          className="bg-[#BD273B] text-white hover:!text-white hover:!border-[#BD273B] px-5 rounded-lg w-[115px] h-[40px]"
          onClick={handleApply}
        >
          Add filter
        </Button>
      </div>
    </Modal>
  );
};

export default FilterModal;
