import { DatePicker } from 'antd';
import TextTypography from 'src/app/components/shared/Typography/Text';
import dayjs from 'dayjs';

interface IProps {
  value: string | null;
  onChange: (val: string) => void;
  clasNames?: string;
  isLabel?: boolean;
}

const DateSelect = ({ value, onChange, clasNames, isLabel = true }: IProps) => {
    
  return (
    <div className="w-full">
      {isLabel ? (
        <label>
          <TextTypography fontSize={14}>Created At</TextTypography>
        </label>
      ) : null}{' '}
      <DatePicker
        placeholder="Choose Date"
        className={`h-[36px] w-full ${clasNames}`}
        value={value ? dayjs(value) : null}
        onChange={(date, dateString) => {
          onChange(dateString);
        }}
        
        format="YYYY-MM-DD"
      />
    </div>
  );
};

export default DateSelect;
