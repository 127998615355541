import React, { useState } from 'react';
import { Badge, Card, Tabs } from 'antd';
import HeadingTypography from 'src/app/components/shared/Typography/Heading';
import { LeadsTable } from './leadsTable';

const LeadTableCard = () => {
  const [activeTab, setActiveTab] = useState('1');

  return (
    <div className="pt-4 bg-gray-100 rounded-lg">
      <Card>
        <div className="border-2 border-black rounded-lg">
          <Tabs
            defaultActiveKey="1"
            onChange={(key) => setActiveTab(key)}
            tabBarStyle={{ borderBottom: '1px solid #d9d9d9' }}
            className="text-lg font-semibold tabs-custom-style"
          >
            <Tabs.TabPane
              key="1"
              tab={
                <>
                  <Badge count={99} className="mt-3">
                    <HeadingTypography
                      fontSize={16}
                      classNames="flex items-center h-[40px] px-[32px]"
                    >
                      All Leads
                    </HeadingTypography>{' '}
                  </Badge>

                  {activeTab === '1' && (
                    <div className="absolute bottom-[0px] z-10 left-0 w-full h-[2px] bg-[#FCD4D6]" />
                  )}
                </>
              }
            >
              <LeadsTable />
            </Tabs.TabPane>

            <Tabs.TabPane
              key="2"
              disabled
              tab={
                <Badge count={22} className="mt-3">
                  <HeadingTypography
                    fontSize={16}
                    classNames="text-gray-400 text-[#DBD7D7] font-normal flex items-center h-[40px] px-[32px]"
                  >
                    Holds
                  </HeadingTypography>
                </Badge>
              }
            />
            <Tabs.TabPane
              key="3"
              disabled
              tab={
                <HeadingTypography
                  fontSize={16}
                  classNames="text-gray-400 text-[#DBD7D7] font-normal flex items-center h-[40px] px-[24px]"
                >
                  Drop-Off
                </HeadingTypography>
              }
            />
            <Tabs.TabPane
              key="3"
              disabled
              tab={
                <HeadingTypography
                  fontSize={16}
                  classNames="text-gray-400 text-[#DBD7D7] font-normal flex items-center h-[40px] px-[24px]"
                >
                  Guarntors
                </HeadingTypography>
              }
            />
          </Tabs>
        </div>
      </Card>
    </div>
  );
};

export default LeadTableCard;
