import React from 'react';
import {  Table } from 'antd';
import HeadingTypography from 'src/app/components/shared/Typography/Heading';
import TextTypography from 'src/app/components/shared/Typography/Text';

const columns = [
  {
    title: <HeadingTypography fontSize={16}>Hot Leads</HeadingTypography>,
    dataIndex: 'hotLeads',
    key: 'hotLeads',
    render: (value: number) => (
      <TextTypography fontSize={16}>{value}</TextTypography>
    ),
  },
  {
    title: <HeadingTypography fontSize={16}>Call Back Later</HeadingTypography>,
    dataIndex: 'callBackLater',
    key: 'callBackLater',
    render: (value: number) => (
      <TextTypography fontSize={16}>{value}</TextTypography>
    ),
  },
  {
    title: <HeadingTypography fontSize={16}>Details Shared</HeadingTypography>,
    dataIndex: 'detailsShared',
    key: 'detailsShared',
    render: (value: number) => (
      <TextTypography fontSize={16}>{value}</TextTypography>
    ),
  },
  {
    title: <HeadingTypography fontSize={16}>Out of Stations</HeadingTypography>,
    dataIndex: 'outOfStations',
    key: 'outOfStations',
    render: (value: number) => (
      <TextTypography fontSize={16}>{value}</TextTypography>
    ),
  },
  {
    title: <HeadingTypography fontSize={16}>Doc Awaiting</HeadingTypography>,
    dataIndex: 'docAwaiting',
    key: 'docAwaiting',
    render: (value: number) => (
      <TextTypography fontSize={16}>{value}</TextTypography>
    ),
  },
  {
    title: <HeadingTypography fontSize={16}>Contacted</HeadingTypography>,
    dataIndex: 'contacted',
    key: 'contacted',
    render: (value: number) => (
      <TextTypography fontSize={16}>{value}</TextTypography>
    ),
  },
 
  {
    title: <HeadingTypography fontSize={16}>Plan Finalized</HeadingTypography>,
    dataIndex: 'planFinalized',
    key: 'planFinalized',
    render: (value: number) => (
      <TextTypography fontSize={14}>{value}</TextTypography>
    ),
  },
  {
    title: <HeadingTypography fontSize={16}>Drop-Off</HeadingTypography>,
    dataIndex: 'deadLead',
    key: 'deadLead',
    render: (value: number) => (
      <TextTypography fontSize={16}>{value}</TextTypography>
    ),
  },
];

const data = [
  {
    key: '1',
    hotLeads: 536,
    callBackLater: 22,
    detailsShared: 47,
    outOfStations: 25,
    docAwaiting: 7,
    contacted: 19,
    deadLead: 673,
    planFinalized: 698,
  },
];

const LeadTable: React.FC = () => {
  return (
    <div className="bg-gray-100 rounded-lg">
      <Table
        scroll={{ x: true }}
        rowClassName="bg-white"
        columns={columns}
        dataSource={data}
        pagination={false}
        className="bg-white rounded-lg shadow-sm"
        components={{
          header: {
            cell: ({ children, ...restProps }: any) => (
              <th {...restProps} className="!bg-white ">
                {children}
              </th>
            ),
          },
        }}
      />
    </div>
  );
};

export default LeadTable;
