import React from 'react';

interface TypographyProps {
  classNames?: string;
  fontSize: 12 | 14 | 16 | 18 | 20 | 24;
  children: React.ReactNode;
  onClick?: () => void;
  style?: Record<string,string>
}

const TextTypography: React.FC<TypographyProps> = ({
  fontSize,
  children,
  classNames,
  onClick,
  style={}
}) => {
  const styles = {
    12: 'text-[12px] leading-[16px]',
    14: 'text-[14px] leading-[18px]',
    16: 'text-[16px] leading-[22px]',
    18: 'text-[18px] leading-[21.6px]',
    20: 'text-[20px] leading-[24.6px]',
    24: 'text-[14px] leading-[28.8px]',
  };

  return (
    <span
    style={style}
      onClick={onClick}
      className={`font-[Helvetica Neue] ${
        styles[fontSize]
      } text-[#6B6969] font-normal text-center ${classNames ?? ''} `}
    >
      {children}
    </span>
  );
};

export default TextTypography;
