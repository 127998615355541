import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import AgentSelect from './AgentSelect';
import StatusSelect from './StatusSelect';
import CitySelect from './CitySelect';
import SegmentSelect from './SegmentSelect';
import ProductTypeSelect from './ProductTypeSelect';
import ChannelTypeSelect from './ChannelTypeSelect';
import QuantitySelect from './QuantitySelect';
import BrandSelect from './BrandSelect';
import TextTypography from 'src/app/components/shared/Typography/Text';
import CrossIcon from 'src/assets/icons/crossIcon';
import DateSelect from './DateSelect';
import { Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd/lib';

type FiltersType = {
  agent: string[];
  status: string[];
  city: string[];
  segment: string[];
  productType: string[];
  channel: string[];
  quantity: string[];
  brand: string[];
  createdAt: string | null;
};

interface ISelectedFilterProps {
  values: FiltersType;
  setvalues: React.Dispatch<React.SetStateAction<FiltersType>>;
}

const SelectedFilters = ({ values, setvalues }: ISelectedFilterProps) => {
  const [filters, setFilters] = useState<FiltersType>({
    agent: [],
    status: [],
    city: [],
    segment: [],
    productType: [],
    channel: [],
    quantity: [],
    brand: [],
    createdAt: null,
  });

  useEffect(() => {
    setFilters(values);
  }, [values]);

  const handleApply = () => {
    setvalues(filters);
  };

  const handleClearFilter = (key: keyof FiltersType) => {
    setFilters((prev) => ({
      ...prev,
      [key]: Array.isArray(prev[key]) ? [] : null,
    }));
  };

  const handleClearAll = () => {
    setFilters({
      agent: [],
      status: [],
      city: [],
      segment: [],
      productType: [],
      channel: [],
      quantity: [],
      brand: [],
      createdAt: null,
    });
  };
  const isFilterApllied = useMemo(
    () =>
      filters.agent.length ||
      filters.status.length ||
      filters.city.length ||
      filters.segment.length ||
      filters.productType.length ||
      filters.channel.length ||
      filters.brand.length ||
      filters.quantity.length ||
      filters.createdAt,
    [filters]
  );

  return (
    <div className="flex flex-wrap gap-2 items-center  p-2 rounded-md">
      {filters.agent.length > 0 && (
        <FilterItem
          handleApply={handleApply}
          label="Agent"
          onClear={() => handleClearFilter('agent')}
          value={values.agent}
        >
          <AgentSelect
            clasNames="!text-[#007AFF] !bg-white text-[12px]"
            isLabel={false}
            value={filters.agent}
            onChange={(val) => setFilters((prev) => ({ ...prev, agent: val }))}
          />{' '}
        </FilterItem>
      )}
      {filters.status.length > 0 && (
        <FilterItem
          handleApply={handleApply}
          label="Status"
          onClear={() => handleClearFilter('status')}
          value={values.status}
        >
          <StatusSelect
            clasNames="!text-[#007AFF] !bg-white text-[12px]"
            isLabel={false}
            value={filters.status}
            onChange={(val) => setFilters((prev) => ({ ...prev, status: val }))}
          />{' '}
        </FilterItem>
      )}
      {filters.city.length > 0 && (
        <FilterItem
          handleApply={handleApply}
          label="City"
          onClear={() => handleClearFilter('city')}
          value={values.city}
        >
          <CitySelect
            clasNames="!text-[#007AFF] !bg-white text-[12px]"
            isLabel={false}
            value={filters.city}
            onChange={(val) => setFilters((prev) => ({ ...prev, city: val }))}
          />{' '}
        </FilterItem>
      )}
      {filters.segment.length > 0 && (
        <FilterItem
          handleApply={handleApply}
          label="Segment"
          onClear={() => handleClearFilter('segment')}
          value={values.segment}
        >
          <SegmentSelect
            clasNames="!text-[#007AFF] !bg-white text-[12px]"
            isLabel={false}
            value={filters.segment}
            onChange={(val) =>
              setFilters((prev) => ({ ...prev, segment: val }))
            }
          />{' '}
        </FilterItem>
      )}
      {filters.productType.length > 0 && (
        <FilterItem
          handleApply={handleApply}
          label="Product"
          onClear={() => handleClearFilter('productType')}
          value={values.productType}
        >
          <ProductTypeSelect
            clasNames="!text-[#007AFF] !bg-white text-[12px]"
            isLabel={false}
            value={filters.productType}
            onChange={(val) =>
              setFilters((prev) => ({ ...prev, productType: val }))
            }
          />
        </FilterItem>
      )}
      {filters.channel.length > 0 && (
        <FilterItem
          handleApply={handleApply}
          label="Channel"
          onClear={() => handleClearFilter('channel')}
          value={values.channel}
        >
          <ChannelTypeSelect
            clasNames="!text-[#007AFF] !bg-white text-[12px]"
            isLabel={false}
            value={filters.channel}
            onChange={(val) =>
              setFilters((prev) => ({ ...prev, channel: val }))
            }
          />{' '}
        </FilterItem>
      )}
      {filters.quantity.length > 0 && (
        <FilterItem
          handleApply={handleApply}
          label="Quantity"
          onClear={() => handleClearFilter('quantity')}
          value={values.quantity}
        >
          <QuantitySelect
            clasNames="!text-[#007AFF] !bg-white text-[12px]"
            isLabel={false}
            value={filters.quantity}
            onChange={(val) =>
              setFilters((prev) => ({ ...prev, quantity: val }))
            }
          />{' '}
        </FilterItem>
      )}
      {filters.brand.length > 0 && (
        <FilterItem
          handleApply={handleApply}
          label="Brand"
          onClear={() => handleClearFilter('brand')}
          value={values.brand}
        >
          <BrandSelect
            clasNames="!text-[#007AFF] !bg-white text-[12px]"
            isLabel={false}
            value={filters.brand}
            onChange={(val) => setFilters((prev) => ({ ...prev, brand: val }))}
          />{' '}
        </FilterItem>
      )}
      {filters.createdAt && (
        <FilterItem
          handleApply={handleApply}
          label="Date"
          onClear={() => handleClearFilter('createdAt')}
          value={[values.createdAt ?? '']}
        >
          <DateSelect
            clasNames="!text-[#007AFF] !bg-white text-[12px] leads-custom-date-selector "
            isLabel={false}
            value={filters.createdAt}
            onChange={(val) =>
              setFilters((prev) => ({ ...prev, createdAt: val }))
            }
          />
        </FilterItem>
      )}

      <div
        className={`${
          isFilterApllied ? 'flex' : 'hidden'
        } items-center  px-3 py-1  bg-white cursor-pointer`}
        onClick={handleClearAll}
      >
        <CrossIcon />
        <TextTypography fontSize={12} classNames=" !pl-2">
          Clear All
        </TextTypography>
      </div>
    </div>
  );
};

interface IFilterItemProps {
  label: string;
  onClear: () => void;
  children: ReactNode;
  value: string[];
  handleApply: () => void;
}
const FilterItem: React.FC<IFilterItemProps> = ({
  label,
  onClear,
  children,
  value = [],
  handleApply,
}: IFilterItemProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const items: MenuProps['items'] = [
    {
      label: (
        <div className="flex">
          {children}
          <Button
            className="bg-[#F2F2F2] text-[#4A4747]  px-5 rounded-lg w-[115px] h-[33px] hover:!text-[#4A4747] ml-3"
            onClick={() => {
              handleApply();
              setOpen(false);
            }}
          >
            Search
          </Button>
        </div>
      ),
      key: '0',
    },
  ];

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); 
    }
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Dropdown menu={{ items }} trigger={['click']} open={open}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <div className="flex items-center bg-white px-2 py-1 rounded-lg border border-solid border-[#DBD7D7]">
              <div
                className="flex items-center cursor-pointer"
                onClick={onClear}
              >
                <CrossIcon />
              </div>
              <div onClick={() => setOpen(true)}>
                <TextTypography fontSize={12} classNames="!pr-2 !pl-2">
                  {label}
                </TextTypography>
                {value?.map((_val) => (
                  <span className="text-[12px] text-[#DBD7D7] ">
                    |
                    <TextTypography
                      classNames="!text-[#007AFF] px-2"
                      fontSize={12}
                    >
                      {_val}
                    </TextTypography>
                  </span>
                ))}
                <DownOutlined className="text-[12px]" />
              </div>
            </div>
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};

export default SelectedFilters;
