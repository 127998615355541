export enum StatusEnum {
    DOC_AWAITING = "Doc Awaiting",
    HOT_LEAD = "Hot Lead",
    CALL_BACK_LATER = "Call Back later",
    DETAILS_SHARED = "Detailed Shared",
    PLAN_FINALIZED = "Plan Finalized",
    CONTACTED = "Contacted",
    OUT_OF_STATION = "Out of Station",
    DEAD_LEAD = "Dead Lead",
    NOT_INTERESTED = "Not Interested",
    PRICE_ISSUE = "Price Issue",
    DOCUMENTATION_ISSUE = "Documentation Issue",
    DATA_PRIVACY_CONCERN = "Data Privacy Concern",
    QUERY_INFORMATION = "Query/Information",
  }
  