import React, { useContext } from 'react';
import type { MenuProps } from 'antd';
import {  Menu, Tooltip } from 'antd';
import {
  DropboxOutlined,
  KeyOutlined, MailOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Routes } from '@routes';
import { useNavigate } from 'react-router-dom';
import { sideBarContext, UserContext } from '@contexts';
import { siderStyle } from '@constants';
import { PermissionsEnum } from '@rasayi-workspace/shared';
import { HasPermission, SignOut } from '@services';
import DashboardIcon from 'src/assets/icons/dashboardIcon';
import AgentsIcon from 'src/assets/icons/agentsIcon';
import LeadsIcon from 'src/assets/icons/leadsIcon';
import CustomersIcon from 'src/assets/icons/customersIcon';
import ProcurementIcon from 'src/assets/icons/procurementIcon';
import OrderIcon from 'src/assets/icons/ordersIcon';
import DeliveryIcon from 'src/assets/icons/deliveryIcon';
import ComplianceIcon from 'src/assets/icons/complianceIcon';
import InstallmentIcon from 'src/assets/icons/installmentIcon';
import TransactionsIcon from 'src/assets/icons/transactionsIcon';
import TransactionVerifiedIcon from 'src/assets/icons/transactionVerifiedIcon';
import VendorsIcon from 'src/assets/icons/vendorsIcon';
import TextTypography from '../Typography/Text';
import SettingsIcon from 'src/assets/icons/settingsIcon';
import LogoutIcon from 'src/assets/icons/logoutIcon';
import HeadingTypography from '../Typography/Heading';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label: <span style={{ marginLeft: 5 }}>{label}</span>,
    type,
  } as MenuItem;
}
interface ISideBarProps {
  collapsed: boolean;
}
export const SidebarMenuItemComponent: React.FC<ISideBarProps> = ({
  collapsed,
}: ISideBarProps) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const items: MenuItem[] = [
    getItem('Dashboard', `/${Routes.dashboard.path}`, <DashboardIcon />),
    getItem('Agents', `/${Routes.users.path}`, <AgentsIcon />),
    getItem('Leads', `/${Routes.leads.path}`, <LeadsIcon />),
    getItem('Customers', `/${Routes.customers.path}`, <CustomersIcon />),
    getItem('Guarantors', `/${ Routes.guarantors.path }`, <UserOutlined/>),
    getItem('Procurement', `/${Routes.procurement.path}`, <ProcurementIcon />),
    getItem('Inventory', 'sub1', <MailOutlined/>, [
      getItem('Inventory', `/${ Routes.inventory.path }`, <MailOutlined/>),
      getItem('Mobile info', `/${ Routes.mobileInfo.path }`, <MailOutlined/>),
    ]),
    getItem('Orders', 'sub2', <OrderIcon />, [
      getItem('All Orders', `/${Routes.orders.path}`, <DropboxOutlined />),
      getItem(
        'Create Order',
        `/${Routes.createOrder.path}`,
        <DropboxOutlined />
      ),
      getItem(
        'Update Order',
        `/${Routes.updateOrder.path}`,
        <DropboxOutlined />
      ),
    ]),
    getItem('Delivery', 'sub3', <DeliveryIcon />, [
      getItem('All Deliveries', `/${Routes.delivery.path}`, <UserOutlined />),
      getItem(
        'Ready to Deliver',
        `/${Routes.readyToDeliver.path}`,
        <UserOutlined />
      ),
    ]),
    getItem('Compliance', `/${Routes.compliance.path}`, <ComplianceIcon />),
    getItem('Instalments', 'sub4', <InstallmentIcon />, [
      getItem(
        'All Instalments',
        `/${Routes.instalments.path}`,
        <UserOutlined />
      ),
      getItem(
        'Pay Instalment',
        `/${Routes.payInstalment.path}`,
        <UserOutlined />
      ),
    ]),
    getItem(
      'Transactions',
      `/${Routes.transactions.path}`,
      <TransactionsIcon />
    ),
    getItem(
      'Transaction Verification',
      `/${Routes.transactionVerification.path}`,
      <TransactionVerifiedIcon />
    ),
    getItem('Vendors', 'sub5', <VendorsIcon />, [
      getItem('All Vendors', `/${Routes.vendors.path}`, <UserOutlined />),
      getItem(
        'Vendor Bank Details',
        `/${Routes.vendorBankDetails.path}`,
        <UserOutlined />
      ),
    ]),

    // getItem(
    //   <TextTypography
    //     fontSize={12}
    //     classNames={`flex ${!collapsed ? 'pl-[24px]' : 'p-0'}`}
    //   >
    //     Account Setting
    //   </TextTypography>,
    //   ``,
    //   ''
    //   // <div className='flex bg-white'>
    //   //   <TextTypography
    //   //     fontSize={12}
    //   //     classNames={`flex ${!collapsed ? 'pl-[24px]' : 'p-0'}`}
    //   //   >
    //   //     Account Setting
    //   //   </TextTypography>
    //   //   <div className="flex items-center justify-center">
    //   //     <Divider
    //   //       className="w-[80%] min-w-[80%] m-0 mt-2"
    //   //       style={{ borderColor: '#DBD7D7' }}
    //   //     />
    //   //   </div>
    //   // </div>
    // ),
    getItem('Admin Settings', 'sub6', <SettingsIcon />, [
      getItem('Brands', `/${Routes.brands.path}`, <SettingOutlined />),
      getItem(
        'Brand Charges',
        `/${Routes.brandCharges.path}`,
        <SettingOutlined />
      ),
      getItem(
        'Product Types',
        `/${Routes.productTypes.path}`,
        <SettingOutlined />
      ),
      getItem(
        'Instalment Plans',
        `/${Routes.instalmentPlans.path}`,
        <SettingOutlined />
      ),
      getItem(
        'Payment Types',
        `/${Routes.paymentTypes.path}`,
        <SettingOutlined />
      ),
      getItem('Channels', `/${Routes.channels.path}`, <UserOutlined />),
      HasPermission(PermissionsEnum.ACTIVITY_LOGS)
        ? getItem(
            'Activity Logs',
            `/${Routes.activityLogs.path}`,
            <TeamOutlined />
          )
        : null,
      getItem('Roles', `/${Routes.roles.path}`, <TeamOutlined />),
      // getItem('Groups', `/${Routes.groups.path}`, <GroupOutlined />),
      getItem('Permissions', `/${Routes.permissions.path}`, <KeyOutlined />),
    ]),
    getItem(
      <TextTypography onClick={() => SignOut()} fontSize={14}>
        Logout
      </TextTypography>,
      ``,
      <LogoutIcon />
    ),
    getItem(
      <div className="absolute flex flex-col">
        <HeadingTypography
          classNames="truncate w-full text-start"
          fontSize={16}
        >
          <Tooltip title={user?.first_name + ' ' + user?.last_name || ''}>
            {user?.first_name + ' ' + user?.last_name || ''}
          </Tooltip>
        </HeadingTypography>
        <TextTypography classNames="truncate w-full text-start" fontSize={16}>
          <Tooltip title={user?.email}>{user?.email}</Tooltip>
        </TextTypography>
      </div>,
      ``,
      <div className="w-9 h-9 bg-gray-300 rounded-full flex-shrink-0"></div>
    ),
  ];

  const { current, setCurrent, openKey, setOpenKey } =
    useContext(sideBarContext);
  const onClickHandler: MenuProps['onClick'] = (e: any) => {
    const keyPath = e.keyPath.slice(1);
    const lastKey = keyPath[keyPath.length - 1];

    setOpenKey(lastKey);
    setCurrent(e.key);
    navigate(e.key);
  };

  return (
    <div className=" h-full flex flex-col">
      <div className="overflow-y-auto ">
        <Menu
          className="!h-[92vh] pb-[20px] custom-sidebar-menu"
          style={{ ...siderStyle }}
          onClick={onClickHandler}
          selectedKeys={[current]}
          mode="inline"
          items={items}
          selectable={true}
          defaultOpenKeys={openKey ? [openKey] : undefined}
        />
      </div>
    </div>
  );
};
