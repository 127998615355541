import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import FilterIcon from 'src/assets/icons/filterIcon';
import FilterModal from './filterModal';
import SelectedFilters from './SelectedFilters';

interface FilterBarProps {
  showSearch?: boolean;
  showFilterButton?: boolean;
  showAddLeadButton?: boolean;
  onSearch?: (value: string) => void;
  onFilterClick?: () => void;
  onAddLeadClick?: () => void;
  onRemoveFilter?: (filter: string) => void;
}
type FiltersType = {
  agent: string[];
  status: string[];
  city: string[];
  segment: string[];
  productType: string[];
  channel: string[];
  quantity: string[];
  brand: string[];
  createdAt: string | null;
};

const FilterBar: React.FC<FilterBarProps> = ({
  showSearch = true,
  showFilterButton = true,
  showAddLeadButton = true,
  onSearch,
  onAddLeadClick,
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [filters, setFilters] = useState<FiltersType>({
    agent: [],
    status: [],
    city: [],
    segment: [],
    productType: [],
    channel: [],
    quantity: [],
    brand: [],
    createdAt: null,
  });
  return (
    <div className="p-3">
      <div className="flex  items-center  bg-white  w-full">
        <div className="flex w-[80%]">
          {showSearch && (
            <Input
              className="max-w-[500px] h-[40px] border border-[#DBD7D7] hover:!border-[#DBD7D7] focus:!border-[#DBD7D7] shadow-none"
              placeholder="Search via name, id, cnic, email"
              prefix={<SearchOutlined />}
              onChange={(e) => onSearch && onSearch(e.target.value)}
            />
          )}

          {/* Filter Button */}
          {showFilterButton && (
            <Button
              type="link"
              className="text-blue-500 flex items-center"
              icon={<FilterIcon />}
              onClick={() => setModalVisible(true)}
            >
              Add Filter
            </Button>
          )}
        </div>
        <div className="w-[20%] flex justify-end">
          {showAddLeadButton && (
            <Button
              className="bg-[#BD273B] text-white hover:!text-white hover:!border-[#BD273B] px-5 rounded-lg w-[115px] h-[40px]"
              onClick={onAddLeadClick}
            >
              Add Lead
            </Button>
          )}
        </div>
      </div>
      {/* Selected Filters */}
      <div className="flex flex-wrap gap-2">
        <SelectedFilters values={filters} setvalues={setFilters} />
      </div>
      <FilterModal
        values={filters}
        setvalues={setFilters}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default FilterBar;
