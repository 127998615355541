import { Select } from 'antd';
import TextTypography from 'src/app/components/shared/Typography/Text';

const { Option } = Select;
interface IProps {
  value: string[];
  onChange: (val: string[]) => void;
  clasNames?: string;
  isLabel?: boolean;
  style?:Record<string,string>
}

const StatusSelect = ({
  value,
  onChange,
  clasNames,
  isLabel = true,
  style={}
}: IProps) => {
  return (
    <div className="w-full">
      {isLabel ? (
        <label>
          <TextTypography fontSize={14}>Status</TextTypography>
        </label>
      ) : null}{' '}
      <Select
      style={style}
        className={`h-[36px] w-full ${clasNames}`}
        mode="multiple"
        allowClear
        placeholder="Search Status"
        value={value}
        onChange={onChange}
      >
        <Option value="active">Active</Option>
        <Option value="inactive">Inactive</Option>
      </Select>
    </div>
  );
};

export default StatusSelect;
