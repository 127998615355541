import React from "react";
import { Card, Row, Col } from "antd";
import HeadingTypography from "../../shared/Typography/Heading";
import TextTypography from "../../shared/Typography/Text";

const leadStats = [
  { title: "Total Leads", count: 23079 },
  { title: "Approved", count: 10540 },
  { title: "Drop-Off", count: 10540 },
  { title: "Declined", count: 5021 },
  { title: "Pending", count: 7530 },
];

const LeadStatsCards: React.FC = () => {
  return (
    <div className="pt-6">
      <Row gutter={[16, 16]}>
        {leadStats.map((stat, index) => (
          <Col key={index} xs={24} md={12} lg={6}>
            <Card
              bordered={false}
              className="rounded-xl shadow-md bg-white"
            >
              <div className="flex justify-between items-center">
                <HeadingTypography fontSize={16}>{stat.title}</HeadingTypography>
                <TextTypography fontSize={16} >{stat.count}</TextTypography >
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};


export default LeadStatsCards;
