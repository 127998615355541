import React, { useState, useEffect } from 'react';
import { DatePicker, Button, Spin, Row, Col, Select, Card, Alert } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { GetRawItems } from '@services';
import { KeyValueRecord } from '@rasayi-workspace/shared';
import { UserOutlined, ShoppingOutlined, DollarOutlined } from '@ant-design/icons';
import { RangeValue } from 'rc-picker/lib/interface'; 

const { RangePicker } = DatePicker;
const { Option } = Select;

interface StatisticsData {
    [status: string]: number | any;
}

const fetchStatistics = async (start: string, end: string): Promise<KeyValueRecord> => {
    const params = new URLSearchParams({ start, end });
    const data = await GetRawItems<StatisticsData>(`/order/dynamic_stats?${params.toString()}`);
    return data;
};

const StatisticsComponent: React.FC = () => {
    const initialStartDate = dayjs('2020-01-01');
    const initialEndDate = dayjs();

    const [selectedRange, setSelectedRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>([
        initialStartDate,
        initialEndDate,
    ]);
    const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
    const [selectedAgent, setSelectedAgent] = useState<string | null>(null);
    const [selectedBrand, setSelectedBrand] = useState<string | null>(null);
    const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
    const [selectedSegment ,setSelectedSegment] = useState<string | null>(null);
    const [selectedCompliance, setSelectedCompliance] = useState<string | null>(null);

    const { data, error, isFetching, refetch } = useQuery<StatisticsData, Error>(
        ['statistics', selectedRange],
        () => {
            if (selectedRange) {
                const [start, end] = selectedRange;
                return fetchStatistics(start.toISOString(), end.toISOString());
            }
            return Promise.reject(new Error('No date range selected'));
        },
        {
            enabled: false,
            onError: (error: Error) => {
                console.error('Error fetching statistics', error);
            },
        }
    );

    useEffect(() => {
        if (selectedRange) {
            refetch();
        }
    }, [selectedRange, refetch]);

    const filteredAgents = selectedChannel
    ? data?.agent?.[selectedChannel]?.map((agent: { agentName: string; }) => agent.agentName) || []
    : Object.keys(data?.agent || {}).flatMap((channel) =>
          data?.agent?.[channel]?.map((agent: { agentName: StringConstructor; }) => agent.agentName) || []
      );

      const filteredBrands = selectedChannel
      ? data?.brand?.[selectedChannel] || [] 
      : []; 
  
        const onDateChange = (dates: RangeValue<Dayjs>, formatString: [string, string]) => {
        
            if (dates && dates[0] && dates[1]) {
                setSelectedRange([dates[0], dates[1]]);
            } else {
                setSelectedRange(null);
            }
        };

    const onSubmit = () => {
        if (selectedRange) {
            refetch();
        }
    };

    const renderStyledCard = (icon: React.ReactNode, value: number | string, description: string, bgColor: string) => (
        <Card
            style={{
                backgroundColor: bgColor,
                borderRadius: '10px',
                color: '#333',
                minWidth: '150px',
                textAlign: 'center',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
            }}
            bodyStyle={{ padding: '20px' }}
        >
            <div style={{ fontSize: '2rem', color: 'black' }}>{icon}</div>
            <div style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'black', marginTop: '10px' }}>{value}</div>
            <div style={{ fontSize: '1rem', color: 'black' }}>{description}</div>
        </Card>
    );

    const renderDropdown = (title: string, options: string[], selectedValue: string | null, setSelectedValue: React.Dispatch<React.SetStateAction<string | null>>, bgColor: string) => (
        <Col xs={24} sm={12} md={8} lg={9} style={{ justifyContent: 'center' }}>
            <Card style={{ backgroundColor: bgColor, marginTop: 20, boxShadow: 'rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px' }}>
                <h3 className='capitalize'>{title}</h3>
                <Select
                    style={{ width: 200 }}
                    placeholder={`Select a ${title.toLowerCase()}`}
                    value={selectedValue}
                    onChange={setSelectedValue}
                >
                    {options.map(option => (
                        <Option key={option} value={option}>
                            {option.toUpperCase()}
                        </Option>
                    ))}
                </Select>
            </Card>
        </Col>
    );

    return (
        <div>
            <RangePicker onChange={onDateChange} defaultValue={[initialStartDate, initialEndDate]} />
            <Button onClick={onSubmit} type="primary" style={{ marginLeft: 8 }}>
                Fetch Statistics
            </Button>

            {isFetching ? (
                <div className="spinner-container">
                    <Spin size="large" />
                </div>
            ) : error ? (
                <Alert message="Error loading statistics" description={error.message} type="error" showIcon />
            ) : (
                <>
                    {data && data.totalOrders === 0 ? (
                        <div className='text-center'>No data found</div>
                    ) : (
                        <Row gutter={[16, 16]} style={{ marginTop: 20, justifyContent: 'center' }}>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                {renderStyledCard(<UserOutlined />, data?.totalOrders?.toLocaleString('en-US') || 0, 'Total Orders', '#e0f7fa')}
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                {renderStyledCard(<ShoppingOutlined />, data?.price?.averageOrderValue?.toLocaleString('en-US') || 0, 'Average Order Value', '#fff9c4')}
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                {renderStyledCard(<DollarOutlined />, data?.price?.totalCustomerPrice?.toLocaleString('en-US') || 0, 'Total Customer Price', '#c8e6c9')}
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                {renderStyledCard(<DollarOutlined />, data?.price?.totalRetailPrice?.toLocaleString('en-US') || 0, 'Total Retail Price', '#d1c4e9')}
                            </Col>
                        {renderDropdown('Order', Object.keys(data?.order || {}), selectedOrder, setSelectedOrder, 'white')}
                        {renderDropdown('Channel', Object.keys(data?.channel || {}), selectedChannel, setSelectedChannel, 'white')}
                        {renderDropdown('Segment', Object.keys(data?.segment || {}), selectedSegment, setSelectedSegment, 'white')}
                        {renderDropdown('Agent', filteredAgents, selectedAgent, setSelectedAgent, 'white')}
                        {renderDropdown('Compliance', Object.keys(data?.compliance || {}), selectedCompliance, setSelectedCompliance, 'white')}
                        {renderDropdown('Brand', filteredBrands, selectedBrand, setSelectedBrand, 'white')}
                        </Row>
                    )}
                </>
            )}
        </div>
    );
};

export default StatisticsComponent;
