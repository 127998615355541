import {  StatusEnum } from "../enums/rasayi/lead-status.enums";

export const VERSION_APP = '2.0.1';

type StatusProperties = {
  bg: string;
  color: string;
};

export const STATUS_OPTIONS: Record<StatusEnum, StatusProperties> = {
  [StatusEnum.DOC_AWAITING]: { bg: "#D9FFDB", color: "#078C0E" },
  [StatusEnum.HOT_LEAD]: { bg: "#FBFFD9", color: "#7F8C07" },
  [StatusEnum.CALL_BACK_LATER]: { bg: "#FFD9D9", color: "#8C0000" },
  [StatusEnum.DETAILS_SHARED]: { bg: "#EED9FF", color: "#4E008C" },
  [StatusEnum.PLAN_FINALIZED]: { bg: "#D9E7FF", color: "#073A8C" },
  [StatusEnum.CONTACTED]: { bg: "#D9F9FF", color: "#07788C" },
  [StatusEnum.OUT_OF_STATION]: { bg: "#FFEFD9", color: "#8C5307" },
  [StatusEnum.DEAD_LEAD]: { bg: "#F2F2F2", color: "#999999" },
  [StatusEnum.NOT_INTERESTED]: { bg: "#E0D9FF", color: "#400101" },
  [StatusEnum.PRICE_ISSUE]: { bg: "#D9FFF6", color: "#2ABA98" },
  [StatusEnum.DOCUMENTATION_ISSUE]: { bg: "#B2EED1", color: "#02542D" },
  [StatusEnum.DATA_PRIVACY_CONCERN]: { bg: "#E6E6E6", color: "#4A4A4A" },
  [StatusEnum.QUERY_INFORMATION]: { bg: "#C8EECB", color: "#045408" },
};

