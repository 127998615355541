import { ReactNode, useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  MenuProps,
  message,
  Space,
  Table,
  Tooltip,
} from 'antd';
import {
  IItemsWithCount,
  ILeadEntity,
  IUserEntity,
  KeyValueRecord,
  transformToFormattedTime,
} from '@rasayi-workspace/shared';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { ITableState } from '@interfaces';
import { LeadModalComponent } from '../add-edit';
import { ColumnsType } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { EMPTY_INITIAL_ITEMS, GetTableItems } from '@services';
import TextTypography from 'src/app/components/shared/Typography/Text';
import FilterBar from './filterBar/FilterBar';
import { TableProps } from 'antd/lib';
import {
  MapAntDesignSearchFilter,
  MapAntDesignSortOrder,
  RemoveUndefinedKeyPairs,
} from '@helpers';
import { SorterResult } from 'antd/es/table/interface';
import MoreIcon from 'src/assets/icons/moreIcon';
import { StatusEnum } from '../../../../../../../../libs/shared/src/enums/rasayi/lead-status.enums';
import { ArrowUpOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import BillIcon from 'src/assets/icons/billIcon';

export const LeadsTable = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLeadModalOpened, setIsLeadModalOpened] = useState(false);
  const [leadsData, setLeadsData] =
    useState<IItemsWithCount<ILeadEntity>>(EMPTY_INITIAL_ITEMS);

  const [tableState, setTableState] = useState<ITableState>({
    ...DEFAULT_TABLE_STATE,
    sortBy: { created_at: 'DESC' },
  });

  const [editingLeadId, setEditingLeadId] = useState('');

  const { refetch, isFetching } = useQuery<
    IItemsWithCount<ILeadEntity>,
    AxiosError
  >({
    ...BASE_QUERY_OPTIONS,
    queryKey: ['leads'],
    queryFn: () =>
      GetTableItems<ILeadEntity>(
        'lead',
        ['product_type', 'channel', 'brand'],
        [],
        tableState
      ),
    onSuccess: (returnedResult: IItemsWithCount<ILeadEntity>): void =>
      setLeadsData(returnedResult),
    onError: () => setLeadsData(EMPTY_INITIAL_ITEMS),
  });
  const handleChange: TableProps<ILeadEntity>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
      [(sorter as SorterResult<ILeadEntity>).columnKey as keyof ILeadEntity]:
        MapAntDesignSortOrder((sorter as SorterResult<ILeadEntity>).order),
    });

    sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

    setTableState({
      ...tableState,
      page: pagination.current || tableState.page,
      pageSize: pagination.pageSize || tableState.pageSize,
      sortBy: sortBy,
      search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters)),
    });
  };

  const columns: ColumnsType<ILeadEntity> = [
    {
      title: 'ID',
      dataIndex: 'internal_id',
      key: 'internal_id',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[134px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },
    {
      title: 'Agent',
      key: 'user.first_name',
      ellipsis: true,
      sorter: () => 0,
      render: (_: string, item: ILeadEntity) => {
        const name = item.user?.first_name;

        return (
          <Tooltip title={name}>
            <TextTypography
              fontSize={16}
              classNames="cursor-pointer truncate flex h-[40px] truncate items-center w-[120px]"
            >
              {name}
            </TextTypography>
          </Tooltip>
        );
      },
    },
    {
      title: 'First name',
      dataIndex: 'first_name',
      key: 'first_name',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[120px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },
    {
      title: 'Last name',
      dataIndex: 'last_name',
      key: 'last_name',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[120px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[218px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'mobile',
      key: 'mobile',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[159px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },
    {
      title: 'Secondary Number',
      dataIndex: 'mobile',
      key: 'mobile',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[159px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },
    {
      title: 'CNIC',
      dataIndex: 'cnic_number',
      key: 'cnic_number',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[160px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'lead_status',
      key: 'lead_status',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (status: string | StatusEnum) => (
        <div className="h-[40px] w-[146px]">
          {/* <StatusSelect
          
          isMulti={false}
          isLabel={false}
            clasNames={`cursor-pointer flex h-[40px] truncate items-center w-[146px] rounded-lg`}
            value={[status]}
            onChange={(val)=>console.log(val)}
          /> */}
          {/* <StatusSelect
          // isMulti={false}
          style={{
            backgroundColor: STATUS_OPTIONS['Call Back later']?.bg,
            color: STATUS_OPTIONS['Call Back later']?.color,
          }}
            clasNames="text-[12px] leads-status-dropdown"
            isLabel={false}
            value={[status]}
            onChange={(val) => console.log('val')}
          /> */}
          <TextTypography
            // style={{
            //   backgroundColor: STATUS_OPTIONS[status]?.bg,
            //   color: STATUS_OPTIONS[status]?.color,
            // }}
            fontSize={14}
            classNames={`cursor-pointer flex  truncate items-center  !px-[8px] !py-[6px]  rounded-lg !w-fit`}
          >
            {status}
          </TextTypography>
        </div>
      ),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[146px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },

    {
      title: 'Organization',
      dataIndex: 'organization_name',
      key: 'organization_name',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[172px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },
    {
      title: 'Segment',
      dataIndex: 'segment',
      key: 'segment',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[124px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },
    {
      title: 'Channel',
      key: 'channel.name',
      ellipsis: true,
      sorter: () => 0,
      render: (_: string, item: ILeadEntity) => {
        const channel = item.channel?.name;

        return (
          <Tooltip title={channel}>
            <TextTypography
              fontSize={16}
              classNames="cursor-pointer flex  h-[40px] truncate items-centerw-[140px]"
            >
              {channel}
            </TextTypography>
          </Tooltip>
        );
      },
    },
    {
      title: 'Quantity',
      key: 'brand.name',
      ellipsis: true,
      sorter: () => 0,
      render: (_: string) => {
        return (
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[108px]"
          >
            {0}
          </TextTypography>
        );
      },
    },
    // {
    //   title: 'Brand',
    //   key: 'brand.name',
    //   ellipsis: true,
    //   sorter: () => 0,
    //   render: (_: string, item: ILeadEntity) => {
    //     const brand = item.brand?.name;

    //     return (
    //       <Tooltip title={brand}>
    //         <TextTypography
    //           fontSize={16}
    //           classNames="cursor-pointer flex h-[40px] truncate items-center w-[155px]"
    //         >
    //           {brand}
    //         </TextTypography>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      title: 'Customer Brand',
      dataIndex: 'customer_brand',
      key: 'customer_brand',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[240px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },

    // {
    //   title: 'Product Type',
    //   key: 'product_type.name',
    //   ellipsis: true,
    //   sorter: () => 0,
    //   render: (_: string, item: ILeadEntity) => {
    //     const product_type = item.product_type?.name;

    //     return (
    //       <Tooltip title={product_type}>
    //         <TextTypography
    //           fontSize={16}
    //           classNames="cursor-pointer flex h-[40px] truncate items-center w-[172px]"
    //         >
    //           {product_type}
    //         </TextTypography>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      title: 'Customer Product',
      dataIndex: 'customer_product',
      key: 'customer_product',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={_}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[240px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
      ellipsis: true,
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
      render: (_: string) => (
        <Tooltip title={'hhshs'}>
          <TextTypography
            fontSize={16}
            classNames="cursor-pointer flex h-[40px] truncate items-center w-[301px]"
          >
            {_}
          </TextTypography>
        </Tooltip>
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      render: (value: string) => (
        <TextTypography
          fontSize={16}
          classNames="cursor-pointer flex w-[179px] h-[40px] truncate items-center"
        >
          {transformToFormattedTime(value)}
        </TextTypography>
      ),
      sorter: () => 0,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      align: 'right',
      fixed: 'right',
      key: 'operation',
      render: () => (
        <div className="w-[60px] flex justify-end">
          <ActionModal>
            <Button
              type="text"
              shape="circle"
              icon={<MoreIcon />}
              className="!text-end h-[40px] w-[20px]"
            />
          </ActionModal>
        </div>
      ),
    },
  ];

  const resetModal = () => {
    setIsLeadModalOpened(false);
    setEditingLeadId('');
  };
  const onLeadModalCloseHandler = (newLead: ILeadEntity | IUserEntity) => {
    refetch();
    resetModal();

    messageApi.open(
      !editingLeadId
        ? {
            type: 'success',
            content: `New lead '${newLead?.first_name}' created!`,
          }
        : {
            type: 'success',
            content: Object.keys(newLead).includes('roles')
              ? 'New User Created!'
              : `Lead '${newLead?.first_name}' updated!`,
          }
    );
  };
  const onModalCloseHandler = () => resetModal();
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState]);

  return (
    <>
      {contextHolder}
      {isLeadModalOpened && (
        <LeadModalComponent
          editMode={!!editingLeadId}
          id={editingLeadId}
          onClose={onModalCloseHandler}
          onSubmit={onLeadModalCloseHandler}
        />
      )}
      <FilterBar
        showSearch={true}
        showFilterButton={true}
        showAddLeadButton={true}
        onSearch={(value) => console.log('Searching for:', value)}
        onFilterClick={() => console.log('Filter Button Clicked')}
        onAddLeadClick={() => setIsLeadModalOpened(true)}
        onRemoveFilter={(filter) => console.log('Removed Filter:', filter)}
      />

      <div className="border-2 border-black rounded-lg">
        <Table
          columns={columns}
          dataSource={leadsData.items}
          onChange={handleChange}
          pagination={{
            total: leadsData.count,
            pageSize: leadsData.pageSize,
            current: leadsData.page,
          }}
          loading={isFetching}
          rowKey={'id'}
          size="small"
          scroll={{ x: true }}
        />
      </div>
    </>
  );
};

interface IFilterItemProps {
  children: ReactNode;
}
const ActionModal: React.FC<IFilterItemProps> = ({
  children,
}: IFilterItemProps) => {

  const items: MenuProps["items"] = [
    {
      label: (
        <div className="flex items-center gap-[16px]">
          <EditOutlined className='w-[20px] h-[20px] pt-2' />
          <TextTypography fontSize={14}> Edit Lead</TextTypography>
        </div>
      ),
      key: "edit",
    },
    {
      label: (
        <div className="flex items-center gap-2">
          <EyeOutlined className='w-[20px] h-[20px] pt-2' />
          <TextTypography fontSize={14}>View Lead Details</TextTypography>          
        </div>
      ),
      key: "view",
    },
    {
      label: (
        <div className="flex items-center gap-2">
          <ArrowUpOutlined className='w-[20px] h-[20px] pt-2' />
          <TextTypography fontSize={14}>Ask For Credit Approval</TextTypography>          
        </div>
      ),
      key: "credit",
    },
    {
      label: (
        <div className="flex items-center gap-2">
         <BillIcon />
          <TextTypography fontSize={14}>View Plan Details</TextTypography>          
        </div>
      ),
      key: "plan",
    },
  ];

  return (
    <div>
      <Dropdown menu={{ items }} trigger={['click']} placement='topLeft' overlayStyle={{marginLeft:'0px'}}>
        <a onClick={(e) => e.preventDefault()}>
          <Space wrap direction="vertical">{children}</Space>
        </a>
      </Dropdown>
    </div>
  );
};
