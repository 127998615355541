import { Layout } from 'antd';
import { BaseLayoutComponent } from '@components';
import LeadStatsCards from './LeadStatsCards';
import LeadStatusTable from './LeadStatusTable';
import HeadingTypography from '../../shared/Typography/Heading';
import LeadTable from './leadsTable';
import DateRange from '../../shared/dataRange';

const { Content } = Layout;

export const LeadPageComponent = () => {
  return (
    <>
      <BaseLayoutComponent
        children={
          <Content className="m-5 bg">
            <div className='flex justify-between'>
              <HeadingTypography fontSize={24}>Leads</HeadingTypography>
              <DateRange />
            </div>
            <LeadStatsCards />
            <LeadStatusTable />
            <LeadTable />
          </Content>
        }
      />
    </>
  );
};
