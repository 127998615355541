import React, { useState } from 'react';
import { Tabs } from 'antd';
import LeadTable from './leadsStatusTable';
import HeadingTypography from 'src/app/components/shared/Typography/Heading';

const LeadStatusTable: React.FC = () => {
  const [activeTab, setActiveTab] = useState('1');

  return (
    <div className="pt-6 bg-gray-100 rounded-lg w-full">
      <Tabs
        defaultActiveKey="1"
        onChange={(key) => setActiveTab(key)}
        tabBarStyle={{ borderBottom: '1px solid #d9d9d9' }}
        className="text-lg font-semibold tabs-custom-style"
      >
        <Tabs.TabPane
          key="1"
          className="p-0"
          tab={
            <>
              <HeadingTypography
                classNames="flex items-center h-[40px] px-[24px]"
                fontSize={18}
              >
                Leads
              </HeadingTypography>
              {activeTab === '1' && (
                <div className="absolute bottom-[0px] z-10 left-0 w-full h-[2px] bg-[#BD273B]" />
              )}
            </>
          }
        >
          <LeadTable />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="2"
          disabled
          tab={
            <HeadingTypography
              fontSize={18}
              classNames="text-gray-400 text-[#DBD7D7] font-normal flex items-center h-[40px] px-[24px]"
            >
              Credit Risk
            </HeadingTypography>
          }
        />
      </Tabs>
    </div>
  );
};

export default LeadStatusTable;
