import React from "react";

interface TypographyProps {
  classNames?: string
  fontSize: 12 | 14 | 16 | 18 | 20  | 24;
  children: React.ReactNode;
}

const HeadingTypography: React.FC<TypographyProps> = ({ fontSize, children,classNames }) => {
  const styles = {
    12: "text-[12px] leading-[16px]",
    14: "text-[14px] leading-[18px]",
    16: "text-[16px] leading-[22px]",
    18: "text-[18px] leading-[21.6px]",
    20: "text-[20px] leading-[24.6px] text-[#2E2A2A]",
    24: "text-[24px] leading-[28.8px] text-[#2E2A2A]",
  };

  return (
    <span className={`font-[Helvetica Neue] text-[#4A4747] ${styles[fontSize]} font-medium text-center ${classNames??''}`}>
      {children}
    </span>
  );
};

export default HeadingTypography;
