import React from 'react';

const BillIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.05835 2.08325V12.0583C3.05835 12.8749 3.44168 13.6499 4.10002 14.1416L8.44168 17.3916C9.36668 18.0832 10.6417 18.0832 11.5667 17.3916L15.9083 14.1416C16.5667 13.6499 16.95 12.8749 16.95 12.0583V2.08325H3.05835Z" stroke="#4A4747" stroke-width="1.5" stroke-miterlimit="10"/>
    <path d="M1.66675 2.08325H18.3334" stroke="#4A4747" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M6.66675 6.66675H13.3334" stroke="#4A4747" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66675 10.8333H13.3334" stroke="#4A4747" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
};

export default BillIcon;
