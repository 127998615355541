import { Select } from 'antd';
import TextTypography from 'src/app/components/shared/Typography/Text';

const { Option } = Select;

interface IProps {
  value: string[];
  onChange: (val: string[]) => void;
  clasNames?: string;
  isLabel?: boolean;
}

const ChannelTypeSelect = ({
  value,
  onChange,
  clasNames,
  isLabel = true,
}: IProps) => {
  return (
    <div className="w-full">
      {isLabel ? (
        <label>
          <TextTypography fontSize={14}>Channel</TextTypography>
        </label>
      ) : null}{' '}
      <Select
        className={`h-[36px] w-full ${clasNames}`}
        mode="multiple"
        allowClear
        placeholder="Search Channel"
        value={value}
        onChange={onChange}
      >
        <Option value="online">Online</Option>
        <Option value="offline">Offline</Option>
      </Select>
    </div>
  );
};

export default ChannelTypeSelect;
